.card-image {
  max-width: 100%;
  height: auto;
  width: 250px;
  border-radius: 4px;
}
.card-title {
  color: #000000;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  margin: 15px 0 0 0;
  background-color: unset;
}
.card-subtitle {
  color: #666666;
  font-size: 17px;
  line-height: 1.5rem;
  width: 80%;
  margin: 2rem auto;
  text-align: justify;
  background-color: unset;
}
.project-card {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  padding: 1.5rem;
  border-radius: 10px;
  border: 2px solid rgba(211, 211, 211, 0.3);
  justify-content: center;
}

.project-card-light {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.project-card-dark {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.25s ease;
  background-color: #273044;
}

.project-detail {
  text-align: center;
}
.project-card-light:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 15px 20px -10px;
}
.project-card-dark:hover {
  box-shadow: #d9dbdf 0px 3px 15px;
}
.project-image {
  position: relative;
  text-align: center;
}

.project-image img{
  max-height: 180px;
  width: auto;
  filter: grayscale(1);
  transition: all 0.1s ease-in-out;
}

.project-card:hover .project-image img{
  filter: grayscale(0);
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  gap: 3rem 1rem;
}

.project-card-footer span.project-tag {
  background: #55198b;
  color: #f5f2f4;
  vertical-align: middle;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75rem;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: 0.2s ease-in;
  font-size: 1.2rem;
}

.project-subtitle {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .projects-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem 1rem;
  }
  .project-subtitle {
    font-size: 16px;
    text-align: center;
  }
}
