.social-media-div {
  font-size: 2em;
}

.social-media-div .greyscale-social-icons {
  /* filter: grayscale(100%); */
}

.icon-button {
  margin-bottom: 10px;
}

.icon-button i {
  color: white;
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  height: 3.2rem;
  line-height: 3.2rem;
  margin: 0 0.8rem;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 3.2rem;
  margin-bottom: 10px;
  transition: 0.2s ease-in;
}
.facebook i,
.linkedin i,
.github i,
.gitlab i,
.google i,
.twitter i,
.medium i,
.stack-overflow i,
.instagram i {
  background-color: rgb(100, 100, 100);
}

/* Hover Transition */
.twitter i:hover {
  background-color: #1da1f2;
}
.google i:hover {
  background-color: #ea4335;
}
.gitlab i:hover {
  background-color:  #fca326;
} 
.github i:hover{
  background-color: #333;
}
.linkedin i:hover{
  background-color: #0e76a8;
}
.facebook i:hover{
  background-color: #3b5998;
}
.instagram i:hover{
  background-color: #c13584;
}
.stack-overflow i:hover{
  background-color: #f48024;
}
.medium i:hover {
  background-color: #000;
}

/* Media Query */
@media (max-width: 768px) {
  .social-media-div {
    text-align: center;
  }
}
