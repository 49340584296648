.certificates_container {
    margin: 3rem auto;
    width: 90%;
    display: grid;
    /* grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fill, 1fr); */
}


.certificates_left {
    padding: 3rem 2rem;
    align-self: flex-start;
}

.certificates_container a {
    padding: 0.8rem 1.5rem;
    display: inline-block;
    background-color: rgb(233, 233, 233);
    margin: 0.5rem;
    text-decoration: none;
    text-align: start;
    font-weight: 700;
    border-radius: 5px;
    max-height: 50px;
    color: rgb(85, 26, 139);
    transition: background-color .2s ease;
}

.certificates_container a {
    padding: 0.8rem 1.5rem;
    display: inline-block;
    background-color: rgb(233, 233, 233);
    margin: 0.5rem;
    text-decoration: none;
    text-align: start;
    font-weight: 700;
    border-radius: 5px;
    max-height: 50px;
    color: rgb(85, 26, 139);
    transition: background-color .2s ease;
}

.certificates_container a:hover {
    background-color: rgb(209, 209, 209);
}

.dark-mode .certificates_container a {
    background-color: #273044;
    color: white;
}

.dark-mode .certificates_container a:hover {
    background-color: #425172;
}

@media (min-width: 1024px) {
    .skills-image-div {
    grid-column: 2 / span 2;
    grid-row: 1 / span 6;
    }

    .certificates_container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: repeat(auto-fill, 1fr);
    }
    
    .cetificates-title {
        margin: 5rem 0 0 5rem;
        text-align: start;
    }
}