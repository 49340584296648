.footer-text {
  text-align: center;
  color: #868e96 !important;
  padding: 0rem 3rem;
  position: relative;
}

@media (min-width: 768px) {
  .mobil-text-react-logo {
    display: none;
  }
}

.dark-mode .footer-text {
  color: white !important;
  background-color: unset;
}

.footer-div {
  margin-top: 5rem;
  padding: 1rem 0;
  background-color: #e9e9e9;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-div-text {
  padding-bottom: 1rem;
  text-align: center;
}

.footer-button {
  color: #868e96;
  font-size: 1.2rem;
  margin-left: 2rem;
  margin-top: 0.3rem;
  border: unset;
  height: 50px;
  padding: 1rem;
  align-self: center;
  display: flex;
  align-items: center;
  background-color: white;
  cursor: pointer;
}

.footer-button-active {
  -webkit-box-shadow: 0px 0px 6px 1px rgba(161, 161, 161, 0.6);
  -moz-box-shadow: 0px 0px 6px 1px rgba(161, 161, 161, 0.6);
  box-shadow: 0px 0px 6px 1px rgba(161, 161, 161, 0.6);
}

.dark-mode .footer-button-active {
  -webkit-box-shadow: 0px 0px 6px 1px rgba(255, 255, 255, 0.6);
  -moz-box-shadow: 0px 0px 6px 1px rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 6px 1px rgba(255, 255, 255, 0.6);
}

.dark-mode .footer-div{
  background-color: #273044;
}

@media (min-width: 768px) {
  .footer-div {
    flex-direction:row;
  }
}