.titulo_vanilla {
  /* background-color: #f7df1e; */
  text-shadow: -3px 3px 0px #f7df1e;
  font-weight: 900;
  display: inline-block;
}

.titulo_react {
  text-shadow: -3px 3px 0px #61DBFB;
  font-weight: 900;
  display: inline-block;
}
.titulo_gatsby {
  text-shadow: -3px 3px 0px #9c4ee9;
  font-weight: 900;
  display: inline-block;
}
.titulo_next {
  text-shadow: -3px 3px 0px #a7a7a7;
  font-weight: 900;
  display: inline-block;
}
.titulo_nodejs {
  text-shadow: -3px 3px 0px #77c45f;
  font-weight: 900;
  display: inline-block;
}
.titulo_angular {
  text-shadow: -3px 3px 0px #c45f5f;
  font-weight: 900;
  display: inline-block;
}

.page-content {
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;
  max-width: 100%;
  margin: 0 auto;
  font-family: var(--font-sans);
}
@media (min-width: 600px) {
  .page-content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 800px) {
  .page-content {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1200px) {
  .page-content {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  padding: 1rem;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  color: whitesmoke;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
}
@media (min-width: 1200px) {
  .card {
    height: 350px;
  }
}
.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-size: cover;
  background-position: 0 0;
  transition: transform calc(var(--d) * 1.5) var(--e);
  pointer-events: none;
  transition: all .3s ease-in-out;
  background-position: center;
}
.card:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%);
  transform: translateY(-50%);
  transition: transform calc(var(--d) * 2) var(--e);
}

/* JavaScripts Backgrounds */
.js.card:nth-child(1):before {
  background-image: url(../../../assets/images/projects/js1.jpg);
}

.js.card:nth-child(2):before {
  background-image: url(../../../assets/images/projects/js2.jpg);
}

.js.card:nth-child(3):before {
  background-image: url(../../../assets/images/projects/js3.jpg);
}

.js.card:nth-child(4):before {
  background-image: url(../../../assets/images/projects/js4.jpg);
}

.js.card:nth-child(5):before {
  background-image: url(../../../assets/images/projects/js5.jpg);
}
.js.card:nth-child(6):before {
  background-image: url(../../../assets/images/projects/js6.jpg);
}
.js.card:nth-child(7):before {
  background-image: url(../../../assets/images/projects/js6.jpg);
}
.js.card:nth-child(8):before {
  background-image: url(../../../assets/images/projects/js6.jpg);
}

/* React Backgrounds */
.react.card:nth-last-child(9):before {
  background-image: url(../../../assets/images/projects/react9.jpg);
}
.react.card:nth-last-child(8):before {
  background-image: url(../../../assets/images/projects/react8.jpg);
}
.react.card:nth-last-child(7):before {
  background-image: url(../../../assets/images/projects/react7.jpg);
}
.react.card:nth-last-child(6):before {
  background-image: url(../../../assets/images/projects/react6.jpg);
}
.react.card:nth-last-child(5):before {
  background-image: url(../../../assets/images/projects/react5.jpg);
}
.react.card:nth-last-child(4):before {
  background-image: url(../../../assets/images/projects/react4.jpg);
}
.react.card:nth-last-child(3):before {
  background-image: url(../../../assets/images/projects/react3.jpg);
}
.react.card:nth-last-child(2):before {
  background-image: url(../../../assets/images/projects/react2.jpg);
}
.react.card:nth-last-child(1):before {
  background-image: url(../../../assets/images/projects/react1.jpg);
}

/* Angular Backgrounds */
.angular.card:nth-last-child(1):before {
  background-image: url(../../../assets/images/projects/angular1.jpg);
}

/* Next Backgrounds */
.next.card:nth-child(1):before {
  background-image: url(../../../assets/images/projects/next1.jpg);
}
.next.card:nth-child(2):before {
  background-image: url(../../../assets/images/projects/next2.jpg);
}
.next.card:nth-child(3):before {
  background-image: url(../../../assets/images/projects/next3.jpg);
}
.next.card:nth-child(4):before {
  background-image: url(../../../assets/images/projects/next4.jpg);
}


.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  transition: transform var(--d) var(--e);
  z-index: 1;
  transition: all .3s ease-in-out;
}
.content > * + * {
  margin-top: 1rem;
}

.title {
  font-size: 1.3rem;
  font-weight: bold;
  padding: 1rem;
  line-height: 1.2;
  background-color: rgb(139, 139, 139);
  width: 120%;
}

.dark-mode .title {
  background-color: #35415c;
}

.copy {
  font-family: var(--font-serif);
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.35;
  display: none;
}

.copy li {
  list-style: none; 
}

@media (min-width: 1200px) {
  .copy {
    display: inline-block;
  }
}

.btn {
  cursor: pointer;
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  font-size: .8rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border: none;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
}
.btn:hover {
  background-color: #464646;
  color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 0px 8px 2px rgba(255, 255, 255, 0.75);
  -moz-box-shadow: 0px 0px 8px 2px rgba(255, 255, 255,0.75);
  box-shadow: 0px 0px 8px 2px rgba(255, 255, 255,0.75);
}

@media (hover: hover) and (min-width: 600px) {
  .card:after {
    transform: translateY(0);
  }

  .content {
    transform: translateY(calc(100% - 4.5rem));
  }
  .content > *:not(.title) {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform var(--d) var(--e), opacity var(--d) var(--e);
  }

  .card:hover,
.card:focus-within {
    align-items: center;
  }
  .card:hover:before,
.card:focus-within:before {
    transform: translateY(-4%);
    filter: blur(3px);
  }
  .card:hover:after,
.card:focus-within:after {
    transform: translateY(-50%);
  }
  .card:hover .content,
.card:focus-within .content {
    transform: translateY(0);
  }
  .card:hover .content > *:not(.title),
.card:focus-within .content > *:not(.title) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: calc(var(--d) / 8);
  }

  .card:focus-within:before, .card:focus-within:after,
.card:focus-within .content,
.card:focus-within .content > *:not(.title) {
    transition-duration: 0s;
  }
}

.two-col-projects {
  display: flex;
  flex-direction: column;
}

.two-col-backend {
  display: flex;
  flex-direction: column;
}

.twoCol-img-project {
  margin: 0 2rem;
  order: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}


.twoCol-img-project img {
  max-width: 100%;
  margin-top: 3rem;
  pointer-events: none;
  max-height: 300px;
}

.twoCol-info-project {
  margin: 2rem;
  text-align: justify;
}

.btn-twoCol-info {
  background-color: #e9e9e9;
  border: none;
  padding: 1rem 2rem;
  border-radius: 0.3rem;
  transition: background-color 0.2s ease-in;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1rem 1rem;
  text-transform: capitalize;
  font-size: 1.2rem;
  color: rgb(41, 41, 41);
  text-decoration: none;
}

.btn-twoCol-info:hover {
  background-color: #c9c9c9;
}

.dark-mode .btn-twoCol-info {
  background-color: #273044;
}

.dark-mode .btn-twoCol-info:hover {
  background-color: #47587e;
}

.twoCol-btn-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.btn-twoCol-info:active {
  border: none;
}


@media (min-width: 768px) {
  .two-col-projects {
    flex-direction: row;
  }
  .twoCol-img-project {
    flex: 0 1 600px;
  }
  .twoCol-info-project {
    flex: 0 1 95%;
  }
  .twoCol-img-project {
    order: unset;
  }
  .two-col-backend {
    flex-direction: row;
    max-width: 80%;
    margin: 0 auto;
    align-items: center;
  }
  .two-col-backend h2 {
    padding-top: 3rem;
  }
}

@media (min-width: 1008px) {
  .two-col-backend {
    flex-direction: row;
    max-width: 80%;
    margin: 0 auto;
    align-items: center;
  }

}