.contact-div-main {
  display: flex;
}

.dark-mode a {
  color: white;
}

.contact-div-main > * {
  flex: 1;
}

.contact-title {
  font-size: 65px;
  font-weight: 400;
}

.contact-details,
.contact-details-email {
  font-size: 36px;
  font-weight: 400;
  color: black;
  text-decoration: none;
  display: inline-block;
  padding: 1.5rem 1rem;
  text-align: end;
  border: 3px solid #e9e9e9;
  background-color: #e9e9e9;
  border-radius: 0.3rem;
  margin: 1rem 0.3rem;
  transition: background-color 0.3s ease-in-out;
}

.dark-mode .contact-details,
.dark-mode .contact-details-email {
  background-color: unset;
}

.contact-details:hover,
.contact-details-email:hover {
  background-color: #c7c7c7;
}

.dark-mode .contact-details:hover,
.dark-mode .contact-details-email:hover {
  background-color: #44516d;
}

.contact-subtitle {
  text-transform: uppercase;
}

.contact-text-div {
  margin-top: 1.5rem;
}

.contact-margin-top {
  margin-top: 4rem;
}

.contact-image-div {
  max-width: 100%;
  height: auto;
}

.contact-img {
  display: flex;
  align-items: center;
}

.contact-img img {
  max-width: 100%;
  opacity: 0.9;
}

.dark-mode .contact-img img {
  filter: grayscale(.5);
}

@media (max-width: 768px) {
  .contact-img {
    display: none;
  }
}

/* Media Query */
@media (max-width: 1380px) {
  .contact-title {
    font-size: 56px;
    font-weight: 400;
    text-align: center;
  }

  .contact-title {
    font-size: 40px;
    text-align: center;
  }

  .contact-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .contact-text-div {
    text-align: center;
    margin-top: 1.5rem;
  }

  .contact-details,
  .contact-details-email {
    font-size: 20px;
  }

  .contact-image-div {
    display: none;
  }
}
