.skills-heading {
  font-size: 56px;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}
.skills-subtitle {
  text-transform: uppercase;
  margin: 0px;
}
.subTitle {
  color: #868e96;
}

.soft-skills {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
}

.soft-skills p {
  align-self: center;
}

.main .h2-skills{
  text-transform: uppercase;
  padding: 1rem 1rem;
  display: inline-block;
  border-bottom: 4px solid black;
  margin-top: 5rem;
  background-color: #e9e9e9;
}

.dark-mode .main .h2-skills {
  color: white;
  background-color: #273044;
}

.idioms-container {
  text-align: end;
  padding: 0rem 2rem;
  font-size: 1.4rem;
}


/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .skills-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .soft-skills {
    grid-template-columns: repeat(3, 1fr);
  }
  .soft-skills p{
    align-self: flex-start;
  }
}