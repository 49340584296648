/* React Native Backgrounds */

.react-native.card:nth-last-child(1):before {
    background-image: url(../../../../assets/images/projects/react-native1.jpg);
  }
  
.react-native.card:nth-last-child(2):before {
background-image: url(../../../../assets/images/projects/react-native2.jpg);
}

/* .react-native.card:nth-last-child(3):before {
  background-image: url(../../../../assets/images/projects/react-native3.jpg);
}

.react-native.card:nth-last-child(4):before {
  background-image: url(../../../../assets/images/projects/react-native4.jpg);
}

.react-native.card:nth-last-child(5):before {
  background-image: url(../../../../assets/images/projects/react-native5.jpg);
} */


