.react-logo-text {
    width:54px;
    height:20px;
    padding-top: .3rem;
    padding-left: .3rem;
    position: relative;
    display: none;
    
}

@media (min-width: 768px) {
    .react-logo-text {
        display: inline-block;
    }
}

.react-logo{
    border-radius:50%;
    border:3px solid #00d8ff;
    animation-name:rotate;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

  }

  .react-logo , .react-logo:before , .react-logo:after{
    position:absolute;
    display: inline-block;
    width:45px;
    height:18px;
    border-radius:50%;
    border:2px solid #00d8ff;
  }

  .nucleo , .react-logo , .react-logo:before , .react-logo:after {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nucleo{
    width:9px;
    height:9px;
    border-radius:100%;
    background:#00d8ff;
  }

  .react-logo:before , .react-logo:after{
    content:"";
  }

  .react-logo:after{
    transform:rotate(-57deg);
  }

  .react-logo:before{
    transform:rotate(57deg);
  }
  

  @keyframes rotate{

    100%{
      transform:rotate(360deg);
    }
  }
