.contenedor-svg {
    display: block;
  }
  
  .software-skills {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .software-skills > i {
    color: #868e96;
  }
  
  .software-skills > i:hover {
    color: #645beb;
  }
  .software-skills > p {
    color: #868e96;
    font-size: 16px;
    text-align: center;
  }
  .software-skills > i:hover ~ p {
    color: #645beb;
  }
  
  .software-skills svg{
    fill: #868e96;
    width: 60px;
    height: 60px;
    border: 2px solid #868e96;
    padding: .5rem;
    margin: .2rem .5rem;
  }

  .software-skills svg:hover {
    border: 2px solid #645beb;
  }

  
  .software-skills svg:hover,
  .software-skills > svg:hover ~ p {
    fill: #645beb;
    color: #645beb;
  }
  
  @media (max-width: 768px)  {
    .software-skills svg{
      width: 40px;
      height: 40px;
      padding: .5rem;
      margin: .1rem .3rem;
    }
  }